<template>
    <div>

    </div>
</template>

<script>
export default {
    name: 'GamePG',
    data () {
        return {
            user_token: localStorage.getItem('user')
        }
    },
    mounted() {
        const url = `https://public.pgjksjk.com/web-lobby/?operator_token=57E0D5D7-09B5-2E87-5F7E-7A3BFD705285&operator_player_session=${this.user_token}&language=th`
        // console.log(url);
        window.location.href = url
    }
}
</script>